<template>
  <div>
    <img v-for="item in img" :key="item.pid" :src="item.purl" class="w-100" />
  </div>
</template>

<script>
export default {
  props:{
    img:{
      type:Array,
      default:() => {
        return []
      }
    }
  },
  data(){
    return {
    }
  },
}
</script>

<style scoped>

</style>