<template>
  <div class="bg-white text-l p-t-10 p-b-10">
    <el-row>
      <div :span="16" :offset="4" class="xz-b">
        <el-row>
          <el-col :span="8">
            <el-image :src="classDetial.purl"></el-image>
          </el-col>
          <el-col :span="15" :offset="1">
            <p class="f-s-24 xz-color-black">{{ classDetial.courseTitle }}</p>
            <p style="height: 70px">
              课程时间： {{ classDetial.startTime }} 至
              {{ classDetial.endTime }} | {{ classDetial.ccount }}课时
            </p>
            <div>
              <span>{{ classDetial.courseXnum }}人购买</span>
              <span class="f-l-r">
                <del class="xz-color-del m-r-10"
                  >¥{{ classDetial.courseOriginal }}</del
                >
                <span class="color-or">¥</span>
                <span class="color-or f-s-30">{{
                  classDetial.coursePrice
                }}</span>
              </span>
            </div>
            <div class="m-t-22">
              <span>
                限购人数：<span class="color-or"
                  >{{ classDetial.courseMax }}人</span
                >
                <el-button
                  type="warning"
                  class="f-l-r"
                  @click="goBuy"
                  v-if="!classDetial.ifBuyThisCourse"
                  >{{
                    classDetial.sellOut == 1 ? "立即购买" : "已售罄"
                  }}</el-button
                >
                <el-button
                  type="warning"
                  class="f-l-r"
                  v-else
                  @click="goLookClass()"
                  >去看课</el-button
                >
                <Btn />
                <!-- @click="$f.online" -->
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-row>
    <Pay ref="pay" :detial="classDetial" />
    <Notice ref="notice" :cid="cid" />

  </div>
</template>

<script>
import Notice from "@/components/learningCenter/class/item/notice";

export default {
  components:{
    Notice
  },
  props: {
    classDetial: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      cid:""
    };
  },
  methods: {
    goBuy() {
      if (!this.$f.uid()) return false;
      if (this.classDetial.sellOut != 1) {
        this.$message.error("已售罄");
        return false;
      }
      if (this.classDetial.coursePrice == 0) {
        let data = {
          uid: this.$f.uid(),
          cid: this.classDetial.cid,
          actTypeId: this.classDetial.actTypeId,
        };
        this.Api.Pay.generateOrder(data).then((res) => {
          if (res.status == 200) {
            this.classDetial.ifBuyThisCourse = true;
            this.$confirm("购买成功,是否去观看课程", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            })
              .then(() => {
                this.classDetial.courseXnum += 1;
                this.goLookClass();
              })
              .catch(() => {
                this.classDetial.ifBuyThisCourse = true;
              });
          }
        });
      } else {
        this.$refs.pay.payVisible = true;
      }
    },
    // 去看课
    goLookClass() {
      let data = {
        cid : this.classDetial.cid,
        uid : this.$f.uid()
      }
      this.cid = data.cid
      this.Api.Agreement.userIfSignAgreement(data).then(res => {
        if(res.retuCode == 5028 || res.retuCode == 5025){  // 用户未签署协议
          this.$refs.notice.dialogVisible = true
        }  else {
          let { href } = this.$router.resolve({
            path: "/player",
            query: { cid: this.classDetial.cid },
          });
          window.open(href, "_blank");
        }
      }).catch(() => {
        let { href } = this.$router.resolve({
          path: "/player",
          query: { cid: this.classDetial.cid},
        });
        window.open(href, "_blank");
      })
      // this.$router.push({
      //   path: "/player",
      //   query: {
      //     vid: null,
      //     cid: this.classDetial.cid,
      //   },
      // });
    },
    // 支付成功
    paySuc() {
      console.log("h'h'h");
      this.classDetial.courseXnum += 1;
      this.classDetial.ifBuyThisCourse = true;
    },
  },
  mounted() {
    const s = document.createElement("script");
    s.id = "qd28857711968fc87d30fc7630b445ff76672c13b502"
    s.charset = "utf-8"
    s.async
    s.defer
    s.type = "text/javascript";
    s.src = "https://wp.qiye.qq.com/qidian/2885771196/8fc87d30fc7630b445ff76672c13b502";
    document.body.appendChild(s);
  },
};
</script>

<style lang="less" scoped></style>
