<template>
  <div>
    <el-row>
      <el-col :span="17">
        <el-row style="height: 60px; background: rgba(240, 128, 65, 0.1)">
          <el-col
            :span="4"
            class="m-t-10 newTabs m-l-10 cu"
            v-for="item in tableItem"
            :key="item.id"
            :style="{ color: activeName == item.name ? '#F08041' : '' }"
            @click.native="qieTab(item)"
            >{{ item.text }}</el-col
          >
        </el-row>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.text"
            :name="item.name"
            v-for="item in tableItem"
            :key="item.id"
          >
            <!-- 课程详情组件 -->
            <CourseDetails v-if="activeName == 'first'" :img="img" />
            <!-- 课程目录组件 -->
            <CourseCatalogue
              v-if="activeName == 'second'"
              :cid="cid"
              :CourseCatalogueList="CourseCatalogueList"
              :classDetial="classDetial"
            />
            <!-- 讲师简介 -->
            <Teacher v-if="activeName == 'third'" :teacherList="teacherList" />
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="6" :offset="1"  id="Service" >
        <Service :img="serImg" style="position: fixed;width:300px;top:90px;" v-show="serTop < 0" />
        <Service v-show="serTop >= 0" :img="serImg"/>
        <Recomm v-show="serTop >= 0" />
        <Recomm style="position: fixed;width:300px;top:410px" v-show="serTop < 0"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Recomm from "@/components/dataDownload/recomm";
import CourseDetails from "@/components/curriculum/item/courseDetails";
import CourseCatalogue from "@/components/curriculum/item/courseCatalogue";
import Teacher from "@/components/curriculum/item/teacher";
import Service from "@/components/dataDownload/service";
export default {
  props: {
    cid: {
      type: String,
      default: "",
    },
    classDetial: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Recomm,
    CourseDetails,
    CourseCatalogue,
    Teacher,
    Service,
  },
   mounted() {
    window.addEventListener('scroll',this.handleScroll,true)
  },
  data() {
    return {
      serImg: require("@/assets/img/class/qrCode.png"),
      img: [],
      teacherList: [], // 存放讲师简介
      CourseCatalogueList: [], // 存放课程目录
      activeName: "first",
      tableItem: [
        {
          id: "1",
          name: "first",
          text: "课程详情",
        },
        {
          id: "2",
          name: "second",
          text: "课程目录",
        },
        {
          id: "3",
          name: "third",
          text: "老师简介",
        },
      ],
      serTop: 1,
    };
  },
  created() {
    this.getcidCourseDetails(); // 获取课程详情的详情内容
    this.getCourseCatalogue(); // 获取课程目录
    this.getTeacher(); // 获取讲师简介
  },
  methods: {
    handleScroll(){
      this.serTop = document.getElementById('Service').getBoundingClientRect().top
      console.log(this.serTop);
    },
    qieTab(item) {
      this.activeName = item.name;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getTeacher() {
      let data = {
        cid: this.cid,
      };
      this.Api.Curr.getTeacher(data).then((res) => {
        this.teacherList = res.data;
      });
    },
    getCourseCatalogue() {
      // 获取课程目录
      let data = {
        cid: this.cid,
      };
      this.Api.Curr.getCourseCatalogue(data).then((res) => {
        this.CourseCatalogueList = res.data.data;
      });
    },
    getcidCourseDetails() {
      // 获取课程详情的详情内容
      let data = {
        cid: this.cid,
      };
      this.Api.Curr.getcidCourseDetails(data).then((res) => {
        this.img = res.data;
      });
    },
  },
};
</script>

<style scoped>
.el-tabs >>> .el-tabs__header {
  display: none;
}
.newTabs {
  height: 50px;
  line-height: 50px;
  background: white;
  border-radius: 10px 10px 0 00px;
  /* opacity: 0.8; */
}
/* #Service {
  position: relative;
} */
</style>