<template>
  <div class="xz-bgColor">
    <el-row>
      <Head :classDetial="classDetial" />
      <div class="xz-b m-t-30">
        <Context :cid="cid" :classDetial="classDetial" />
      </div>
    </el-row>
  </div>
</template>

<script>
import Head from "@/components/curriculum/head"
import Context from "@/components/curriculum/context"
export default {
  components:{
    Head,Context
  },
  created(){
    this.cid = this.$route.query.cid // 获取地址栏中的cid
    this.gerClassDetial() // 获取课程的详情
  },
  data(){
    return {
      cid:"",
      classDetial:{}
    }
  },
  methods:{
    gerClassDetial(){
      let data = {
        cid:this.cid,
        loginname:this.$f.phone()
      }
      this.Api.Curr.gerClassDetial(data).then(res => {
        this.classDetial = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>


</style>