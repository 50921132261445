<template>
<div>

<p class="recommTitle m-b-10" style="margin-bottom:10px">专属学习规划老师</p>
  <div class="bg-white p-t-15 p-b-10 b-r-13 service">
    <p class="f-s-18 m-t-16">{{ title }}</p>
    <p class="f-s-14 m-t-10">{{ content }}</p>
    <el-image :src="img" class="m-t-10" style="width: 150px"></el-image>
    <p class="p-b-10">微信扫码即可添加</p>
  </div>
</div>

</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "添加专属学习规划老师",
    },
    content: {
      type: String,
      default: "获取更多课程资料与专属服务",
    },
    img: {},
  },
};
</script>

<style scoped>
.service {
  transition: all .3s;
}
.service:hover {
  top: -10px;
  box-shadow: 0px 8px 17px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
}
.recommTitle {
  border-left: 3px solid #f08041;
  text-align: left !important;
  text-indent: 10px;
}
p {
  margin: 0 ;
}
</style>