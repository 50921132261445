<template>
  <div>
    <el-row v-for="item in teacherList" :key="item.tid" class="teacher">
      <el-col :span="4" style="height: 100%">
        <el-avatar :src="item.turl" :size="80"></el-avatar>
      </el-col>
      <el-col :span="20" class="text-l">
        <p>
          <span class="xz-color-black f-s-22">{{ item.tname }}</span>
          <!-- <span class="m-l-10">小筑教育独家BIM讲师</span> -->
        </p>
        <p class="f-s-16">{{ item.tcontent }}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    teacherList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style scoped>
.teacher {
  border-bottom: 1px #f1f1f1 solid;
}
</style>